import {
  MESSAGE_TYPES,
  MESSAGE_TABS
} from '@/modules/Chat/components/conversation/enums/message-types'

import commerce from './commerce'
import ticket from './ticket'

export default {
  message: {
    type: MESSAGE_TYPES.public,
    tab: MESSAGE_TABS.public,
    attachments: []
  },
  conversationPage: 1,
  unsendedQuoteMessage: undefined,
  unsendedComments: {},
  unsendedQuotedMessages: {},
  typing: false,
  typingUser: {},
  chat: undefined,
  chatEmptyState: true,
  chatTags: [],
  messages: [],
  showMacroCreationModal: false,
  confirmMacroDeleteModal: undefined,
  showVariablesModal: false,
  showMacroEditModal: false,
  macros: [],
  selectedMacro: undefined,
  chatKey: undefined,
  focusedMessageKey: undefined,
  scrollToBottomFlag: false,
  ticketApp: false,
  isTagsPopoverOpen: false,
  isAddContactModalOpen: false,
  addContactModalStep: undefined,
  selectedContactToAdd: undefined,
  selectedContactToUpdate: undefined,
  existingContacts: [],
  addContactLastPayload: {},
  updateContactLastPayload: {},
  currentIntegrator: undefined,
  sendWindowOpen: true,
  integratorMessageError: undefined,
  selectedTemplateMessage: undefined,
  refAutoFocus: undefined,
  updatedTagsMap: {},
  autocompletePublicTags: [],
  showConversationAssumeErrorModal: false,
  showAttachmentFullscreen: false,
  attachmentFullscreenInitialIndexState: undefined,
  chatHistory: undefined,
  person: {},
  customFieldsData: [],
  organizationsCustomFieldsData: [],
  selectOrganizationOptions: [],
  selectedOrganization: null,
  dateToUpdateLastMessageTimer: null,
  currentChatPagination: {
    pageSize: 40,
    page: 1,
    pages: 0,
    pagesLoaded: []
  },
  currentChatNewPageLoading: false,
  buildingChatMessageList: false,
  listMentionedMessageKey: null,
  mentionedMessageKey: null,
  contactStatusList: [],
  defaultEmailAddress: null,
  currentChatAside: undefined,
  chatAi: undefined,
  isDropdownReadyMessagesOpen: false,
  isUploadingRecordedAudio: false,
  ...commerce,
  ...ticket,
  catalogFilters: {}
}
