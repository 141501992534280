import { AxiosInstance, AxiosResponse } from 'axios'

import { STATUS_CODE } from '@/common/helpers/enums/http-status-codes'

import store from '@/store'

export const applyInterceptors = (client: AxiosInstance): AxiosInstance => {
  client.interceptors.response.use(
    (response: AxiosResponse) => response,
    (error: any) => {
      if (error.response?.status === STATUS_CODE.UNAUTHORIZED) {
        store.dispatch('logout', {
          logoutReason: 'expiredSession'
        })
      }

      throw error
    }
  )

  return client
}
