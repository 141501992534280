<template>
  <div>
    <div v-if="showBanner" class="smart-banner">
      <div class="flex items-center">
        <img
          v-lazy="'/images/octa-logo-mini.svg'"
          alt="App Logo"
          class="w-14 h-14 mr-4"
        />
        <div>
          <octa-description class="!text-blueGray-600 font-semibold text-md">
            {{ $t('shareds.sharedComponents.smartBanner.title') }}
          </octa-description>
          <octa-description class="!text-blueGray-500 text-sm">
            {{ $t('shareds.sharedComponents.smartBanner.description') }}
          </octa-description>
        </div>
      </div>
      <div class="flex gap-4">
        <octa-btn @click="redirectToApp">
          {{ $t('shareds.sharedComponents.smartBanner.button') }}
        </octa-btn>
        <octa-icon-btn icon="close" lg @click="dismissBanner" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { useDevice } from '@/common/composables/useDevice'
  import * as Sentry from '@sentry/vue'
  import { ref, onMounted, onBeforeUnmount } from 'vue'

  import {
    OctaBtn,
    OctaIconBtn,
    OctaDescription
  } from '@octadesk-tech/web-components'

  let observer: MutationObserver | undefined
  let attempts = 0

  function debounce(func: (...args: any[]) => void, wait: number) {
    let timeout: ReturnType<typeof setTimeout>

    return (...args: any[]) => {
      clearTimeout(timeout)
      timeout = setTimeout(() => func(...args), wait)
    }
  }

  const observeRoute = () => {
    let oldHref = document.location.href

    const bodyList = document.querySelector('body')

    const observer = new MutationObserver(() => {
      if (oldHref != document.location.href) {
        oldHref = document.location.href
        debounce(checkIfShouldApplyOrRemoveBanner, 1000)()
      }
    })

    const config = {
      childList: true,
      subtree: true
    }
    if (!bodyList) return
    observer.observe(bodyList, config)

    return observer
  }

  const showBanner = ref(false)

  const { isMobile, isIOS, isAndroid } = useDevice()
  const mainContainer = 'main-container'
  const mustOpenOn = ['/chat', '/people/contacts', '/people/organizations']
  const dismissed = ref(false)

  const redirectToApp = () => {
    const androidLink =
      'https://play.google.com/store/apps/details?id=com.octadesk'
    const iosLink = 'https://apps.apple.com/app/id1605995041'

    if (isAndroid.value) {
      window.location.href = androidLink
    } else if (isIOS.value) {
      window.location.href = iosLink
    } else {
      Sentry.captureMessage('Unsupported device', {
        extra: {
          device: navigator.userAgent
        }
      })
    }
  }

  const dismissBanner = () => {
    const mainContainerApp = document.getElementById(mainContainer)
    dismissed.value = true
    removeBanner(mainContainerApp)
  }

  const isOnRightRoute = () => {
    return mustOpenOn.some(path => window.location.pathname.startsWith(path))
  }

  const applyBanner = (el: HTMLElement | null) => {
    if (showBanner.value || dismissed.value) return
    if (!el) {
      if (attempts > 10) return
      setTimeout(() => {
        applyBanner(document.getElementById(mainContainer))
        attempts++
      }, 1000)
      return
    }
    showBanner.value = true
    el.style.height = `calc(100dvh - 92px)`
  }

  const removeBanner = (el: HTMLElement | null) => {
    showBanner.value = false

    if (!el) return
    el.style.height = `100dvh`
  }

  const checkIfShouldApplyOrRemoveBanner = () => {
    const mainContainerApp = document.getElementById(mainContainer)
    if (isOnRightRoute() && isMobile.value) {
      applyBanner(mainContainerApp)
    } else {
      removeBanner(mainContainerApp)
    }
  }

  onBeforeUnmount(() => {
    if (observer) {
      observer.disconnect()
      observer = undefined
    }
  })

  onMounted(() => {
    checkIfShouldApplyOrRemoveBanner()
    observer = observeRoute()
  })
</script>

<style scoped>
  .smart-banner {
    @apply top-0 left-0 right-0 bg-gray-100 text-blueGray-700 flex;
    @apply justify-between items-center p-4 shadow-lg relative gap-2 h-[92px];
    z-index: 1000;
  }
</style>
