import {
  GeneralFilters,
  GeneralFiltersData
} from '@/modules/ChatSettings/financial-management/credits/credits.enum'

import { Credits } from './interfaces'

const state: Credits = {
  productExpenses: {
    hasError: false,
    isLoading: true,
    expenses: [],
    total: 0,
    filters: {
      [GeneralFilters.Product]: GeneralFiltersData.All,
      [GeneralFilters.Date]: ''
    }
  },
  consumptionEstimation: 0,
  categorizedReport: {},
  integratorWhatsappNumbers: {
    hasError: false,
    isLoading: true,
    numbers: []
  }
}

export default state
