import { headers } from '@octadesk-tech/services'

import { getAiAnalyticsApiClient } from '@/modules/Woz/services/ai-analytics-client'

import { MetricTypes } from './interfaces'

export const getVirtualAgentMetrics = async (
  date: { dateIni: string; dateEnd: string },
  ids: string
) => {
  try {
    const response = await getAiAnalyticsApiClient().then(client =>
      client.get(
        `/virtual/agents/metrics?virtualAgentIds=${ids}&startDate=${date.dateIni}&endDate=${date.dateEnd}`,
        {
          headers: {
            ...headers.getAuthorizedHeaders().headers
          }
        }
      )
    )

    return response.data
  } catch (ex) {
    throw new Error('Error on get content gaps', { cause: ex })
  }
}

export const getVirtualAgentConversationStatus = async (payload: {
  agentIds: string
  date: { dateIni: string; dateEnd: string }
  type: MetricTypes
  page: number
}) => {
  try {
    const response = await getAiAnalyticsApiClient().then(client =>
      client.get(
        `/virtual/agents/status?virtualAgentIds=${payload.agentIds}&page=${payload.page}&status=${payload.type}&startDate=${payload.date.dateIni}&endDate=${payload.date.dateEnd}`,
        {
          headers: {
            ...headers.getAuthorizedHeaders().headers
          }
        }
      )
    )

    return response.data
  } catch (ex) {
    throw new Error('Error on get content gaps', { cause: ex })
  }
}
