import { getCookie } from './cookie'

const getParameterByName = (name: string) => {
  if (typeof document !== 'undefined') {
    name = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]')

    const regex = new RegExp('[\\?&]' + name + '=([^&#]*)'),
      results = regex.exec(location.search)

    return results === null
      ? ''
      : decodeURIComponent(results[1].replace(/\+/g, ' '))
  }
}

const _parseUtmz = (utm: string) => {
  let utm_name

  switch (utm) {
    case 'utm_source':
      utm_name = 'utmcsr'

      break

    case 'utm_campaign':
      utm_name = 'utmccn'

      break

    case 'utm_medium':
      utm_name = 'utmcmd'

      break

    case 'utm_term':
      utm_name = 'utmctr'

      break

    case 'utm_content':
      utm_name = 'utmcct'

      break
  }

  const utm_cookie = getCookie('__utmz')

  if (utm_cookie) {
    const utms = utm_cookie.split(utm_name + '=').pop()
    const parts = utms && utms.split('|').shift()
    if (parts && parts.length === 2) {
      return parts.replace(/\(|\)/g, '')
    }
  }
}

const _parseLastUtm = (utm: string) => {
  let utm_index = 0

  switch (utm) {
    case 'utm_source':
      utm_index = 0

      break

    case 'utm_medium':
      utm_index = 1

      break
    case 'utm_campaign':
      utm_index = 2

      break

    case 'utm_term':
      utm_index = 3

      break

    case 'utm_content':
      utm_index = 4

      break
  }

  const utm_cookie = getCookie('last_utm')

  if (utm_cookie) {
    const utms = utm_cookie.split('|')

    if (utms.length >= utm_index) {
      return utms[utm_index]
    }
  }
}

const _getUtmFromUrl = () => {
  let hasUtmValue = false

  const utm_values: Record<string, any> = {
    utm_source: '',
    utm_medium: '',
    utm_term: '',
    utm_content: '',
    utm_campaign: '',
    gclid: '',
    fbclid: ''
  }

  Object.keys(utm_values).forEach((utm: string) => {
    utm_values[utm] = getParameterByName(utm)

    if (getParameterByName(utm) && !hasUtmValue) {
      hasUtmValue = true
    }
  })

  return hasUtmValue ? utm_values : false
}

const _getUtmFromCookies = () => {
  let hasUtmValue = false

  const utm_values: Record<string, any> = {
    utm_source: '',
    utm_medium: '',
    utm_campaign: '',
    utm_content: '',
    utm_term: ''
  }

  if (!hasUtmValue) {
    Object.keys(utm_values).forEach(utm => {
      const parsed = _parseLastUtm(utm)
      if (parsed && parsed !== 'not%20set') {
        utm_values[utm] = parsed

        hasUtmValue = true
      }
    })
  }

  Object.keys(utm_values).forEach(utm => {
    const utm_cookie = getCookie(utm)

    if (
      utm_cookie !== null &&
      utm_cookie !== '' &&
      utm_cookie !== 'n/a' &&
      utm_cookie !== undefined
    ) {
      utm_values[utm] = utm_cookie

      hasUtmValue = true
    }
  })

  if (!hasUtmValue) {
    Object.keys(utm_values).forEach(utm => {
      const parsed = _parseUtmz(utm)

      if (parsed && parsed !== 'not%20set') {
        utm_values[utm] = parsed

        hasUtmValue = true
      }
    })
  }

  return hasUtmValue ? utm_values : false
}

export const getUtmValues = () => {
  const utm_from_url = _getUtmFromUrl()

  if (utm_from_url) {
    return utm_from_url
  }

  const utm_from_cookies = _getUtmFromCookies()

  if (utm_from_cookies) {
    return utm_from_cookies
  }

  return false
}
