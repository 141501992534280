import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

import Storage from '@octadesk-tech/storage'

import { AbVariants } from '@/modules/Chat/components/conversation/enums/ab-variants'
import { BOT_VERSION } from '@/modules/Chat/components/conversation/enums/bot-version'
import { MESSAGE_TABS } from '@/modules/Chat/components/conversation/enums/message-types'
import { enums } from '@/modules/Chat/components/conversation/services/enums'
import cookieHelper from '@/modules/Chat/helpers/cookie-helper'
import IntegratorNames from '@/modules/Chat/helpers/integrator-names'

import eventsMapper from '../events-mapper'

dayjs.extend(utc)

/**
 * Person
 */
export const getPerson = ({ person }) => person

/** /Person */
export const currentChat = ({ chat }) => chat

export const isCurrentChatWhatsappOfficial = ({ chat }) =>
  IntegratorNames.WhatsAppApis.includes(chat?.integrator?.name)

export const currentChatChannel = ({ chat }) => chat?.channel

const addStoryAttachments = (attachments, message) => {
  const attachs = message?.quotedMessage?.attachments
  if (!attachs?.length) return attachments

  const attach = attachs[0]
  return [
    ...attachments,
    {
      ...attach,
      _id: attach.urlEncrypted
    }
  ]
}

const addAdsAttachments = (attachments, message) => {
  const ad = message?.customFields?.ad
  if (!ad) return attachments

  return [
    ...attachments,
    {
      _id: ad.srcId,
      url: ad.mediaUrl,
      mimeType: ad.mediaType
    }
  ]
}

const addMessageAttachments = (attachments, message) => {
  const attachs = message.attachments
  if (!attachs?.length) return attachments

  return attachments.concat(
    attachs.filter(attach => attach.url || attach.pttUrl)
  )
}

export const currentChatAttachments = ({ chat, chatHistory }) => {
  const self = chatHistory || chat

  return self?.messages?.reduce((attachments, message) => {
    attachments = addMessageAttachments(attachments, message)

    attachments = addAdsAttachments(attachments, message)

    attachments = addStoryAttachments(attachments, message)

    return attachments
  }, [])
}

export const getChatEmptyState = ({ chatEmptyState }) => chatEmptyState

export const currentChatTags = ({ chatTags }) => chatTags

export const messageType = ({ message }) => message.type

export const messageTab = ({ message }) => message.tab

export const isInternalTab = ({ message }) =>
  message.tab === MESSAGE_TABS.internal

export const isWozTab = ({ message }) => message.tab === MESSAGE_TABS.woz

export const attachments = ({ message }) => message.attachments

export const isUploadingRecordedAudio = ({ isUploadingRecordedAudio }) => {
  return isUploadingRecordedAudio
}

export const typing = ({ typing }) => typing
export const typingUser = ({ typingUser }) => typingUser

export const chatKey = ({ chat, chatKey }) => (chat ? chat.key : chatKey)

export const inConversationStatus = ({ chat }) =>
  chat ? chat.status === 0 || chat.status === 1 : false

export const conversationMessageAttachments = ({ chat, messages }) => {
  if (chat) {
    let events = chat.messages.map(m => m)

    const chatMessages = messages.filter(m => m.chatKey === chat.key)
    events = events.concat(
      chatMessages.filter(m => !chat.messages.some(cm => cm.key === m.key))
    )

    return events.flatMap(ev => ev.attachments)
  } else {
    return []
  }
}

export const focusedMessageKey = ({ focusedMessageKey }) => focusedMessageKey

export const events = ({ chat, messages }) => eventsMapper(chat, messages)

export const showMacroCreationModal = ({ showMacroCreationModal }) =>
  showMacroCreationModal

export const confirmMacroDeleteModal = ({ confirmMacroDeleteModal }) =>
  confirmMacroDeleteModal

export const showVariablesModal = ({ showVariablesModal }) => showVariablesModal

export const showMacroEditModal = ({ showMacroEditModal }) => showMacroEditModal

export const macros = ({ macros }) => macros

export const availableMacros = ({ macros }, getters) => {
  const { agent } = getters

  const permission = m =>
    (agent && m.permission === agent.id && m.creatorId === agent.id) ||
    ['all-view', 'all-view-edit'].includes(m.permission)

  return macros.filter(m => permission(m))
}

export const selectedMacro = ({ selectedMacro }) => selectedMacro

export const createdBy = ({ chat }) => chat?.createdBy

export const customFields = ({ chat }) =>
  (chat && chat.customFields) || undefined

export const unsendedComments = ({ unsendedComments }) => unsendedComments

export const unsendedQuotedMessages = ({ unsendedQuotedMessages }) =>
  unsendedQuotedMessages

export const currentChatIsClosed = ({ chat }) =>
  [
    enums.chatStatus.closed,
    enums.chatStatus.closedKb,
    enums.chatStatus.closedMissed
  ].includes(chat?.status)

export const unsendedQuoteMessage = ({ unsendedQuoteMessage }) =>
  unsendedQuoteMessage

export const mentionedMessageKey = ({ mentionedMessageKey }) =>
  mentionedMessageKey

export const listMentionedMessageKey = ({ listMentionedMessageKey }) =>
  listMentionedMessageKey

export const conversationPage = ({ conversationPage }) => conversationPage

export const scrollToBottomFlag = ({ scrollToBottomFlag }) => scrollToBottomFlag

export const isTagsPopoverOpen = ({ isTagsPopoverOpen }) => isTagsPopoverOpen

export const isAddContactModalOpen = ({ isAddContactModalOpen }) =>
  isAddContactModalOpen

export const selectedContactToAdd = ({ selectedContactToAdd }) =>
  selectedContactToAdd

export const selectedContactToUpdate = ({ selectedContactToUpdate }) =>
  selectedContactToUpdate

export const addContactModalStep = ({ addContactModalStep }) =>
  addContactModalStep

export const existingContacts = ({ existingContacts }) => existingContacts

export const addContactLastPayload = ({ addContactLastPayload }) =>
  addContactLastPayload

export const updateContactLastPayload = ({ updateContactLastPayload }) =>
  updateContactLastPayload

export const selectedTemplateMessage = ({ selectedTemplateMessage }) =>
  selectedTemplateMessage

// integrators
export const currentIntegrator = ({ currentIntegrator }) => currentIntegrator

export const sendWindowOpen = ({ sendWindowOpen }) => sendWindowOpen

export const integratorMessageError = ({ integratorMessageError }) =>
  integratorMessageError

// onboarding

export const octavioChatKey = () => Storage.getItem('octavioChatKey')

export const isCurrentChatOctavio = (_, { currentChat, octavioChatKey }) => {
  return octavioChatKey === currentChat.key
}

export const currentChatType = (_, { isCurrentChatOctavio }) => {
  return isCurrentChatOctavio ? 'octávio' : 'customer'
}

export const emptyStateVariantOrigin = () => {
  const cookieName = 'octa-chat-empty-state-variant'
  const variantCookie = cookieHelper.getCookie(cookieName)
  return variantCookie === AbVariants.A ? 'test' : 'original'
}

export const getEnabledExtensionsFromIntegrator = getters => {
  const { currentIntegrator } = getters

  if (!currentIntegrator) return false

  if (currentIntegrator?.supportedExtensions.length > 1) {
    return currentIntegrator?.supportedExtensions
  }

  const channel = currentIntegrator.channels.find(
    ch => getters.chat && ch.name === getters.chat.channel
  )

  if (!channel) return false

  if (!channel?.supportedExtensions || !channel?.supportedExtensions.length) {
    return false
  }

  return channel?.supportedExtensions
}

export const showConversationAssumeErrorModal = ({
  showConversationAssumeErrorModal
}) => showConversationAssumeErrorModal

export const isAttachmentFullscreenVisible = ({ showAttachmentFullscreen }) =>
  showAttachmentFullscreen

export const attachmentFullscreenInitialIndex = ({
  attachmentFullscreenInitialIndexState
}) => attachmentFullscreenInitialIndexState

export const currentChatPage = ({ currentChatPagination }) =>
  currentChatPagination.page

export const currentChatHasMorePage = ({ currentChatPagination }) =>
  currentChatPagination.page < currentChatPagination.pages

export const currentChatPagesLoaded = ({ currentChatPagination }) =>
  currentChatPagination.pagesLoaded

export const currentChatNewPageLoading = ({ currentChatNewPageLoading }) =>
  currentChatNewPageLoading

export const dateToUpdateLastMessageTimer = ({
  dateToUpdateLastMessageTimer
}) => dateToUpdateLastMessageTimer

export const buildingChatMessageList = ({ buildingChatMessageList }) =>
  buildingChatMessageList

export const getCurrentChatUnreadMessagesAmountPerPage = ({ chat }) =>
  chat?.messages?.filter(message => !message.agentRead).length

export const isCurrentChatWithAskMeBot = ({ chat }) =>
  BOT_VERSION.ASK_ME.includes(chat?.botVersion)

export const getContactStatusList = ({ contactStatusList }) => contactStatusList

export const currentChatAside = ({ currentChatAside }) => currentChatAside

export const chatAi = ({ chatAi }) => chatAi

export const isDropdownReadyMessagesOpen = ({ isDropdownReadyMessagesOpen }) =>
  isDropdownReadyMessagesOpen

export const filteredChargeWindows = ({ chat }) =>
  chat.chargeWindows
    .filter(item => dayjs(item.expiresAt).valueOf() > dayjs().valueOf())
    .sort((a, b) => new Date(b.expiresAt) - new Date(a.expiresAt))

export const getChargeWindows = ({ chat }) => chat.chargeWindows
