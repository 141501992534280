import axios, { AxiosInstance } from 'axios'
import axiosRetry from 'axios-retry'

import { url } from '@octadesk-tech/services'

import { applyInterceptors } from '@/common/helpers/utils/token'

const API_CLIENTS = new Map<string, AxiosInstance>()

export const createClient = (options = {}) => axios.create(options)

export const getClient = async (
  api: string,
  options?: { [key: string]: any }
): Promise<AxiosInstance> => {
  if (API_CLIENTS.has(api)) {
    return API_CLIENTS.get(api) as AxiosInstance
  }

  const baseURL = await url.getAPIURL(api)

  if (!baseURL || !baseURL.length) {
    throw new Error(`URL for api ${api} not found`)
  }

  const client = createClient({ baseURL, ...options })

  applyInterceptors(client)

  axiosRetry(client, {
    retries: 5,
    retryDelay: retryCount => {
      return retryCount * 1000
    }
  })

  API_CLIENTS.set(api, client)

  return API_CLIENTS.get(api) as AxiosInstance
}

export default createClient()
