import { ActionContext } from 'vuex'

import dayjs from 'dayjs'

import {
  getWozAgents,
  toggleEnable,
  getWozAgent,
  updateAgent,
  deleteAgent
} from '@/modules/Woz/agents/agents.service'
import { WOZ_AGENT_EDIT_TAB } from '@/modules/Woz/enums'

import i18n from '@/common/i18n'

import { RootState } from '@/store/interfaces'

import { WOZAgentsState, WozAgent } from './interfaces'
import * as types from './mutation-types'

export const loadAgents = async (
  context: ActionContext<WOZAgentsState, RootState>,
  payload: {
    name?: string
    active?: boolean
  }
) => {
  context.commit(types.SET_WOZ_AGENTS_LOADING, true)

  context.commit(types.SET_WOZ_CURRENT_AGENT, null)

  const agents = await getWozAgents(payload)
    .catch(ex => console.error('Error on get woz agents', { ex }))
    .finally(() => context.commit(types.SET_WOZ_AGENTS_LOADING, false))

  const wozCopilotAgent = agents.find((d: WozAgent) => d.isWoz)

  context.commit(
    types.SET_WOZ_AGENTS,
    agents.filter((d: WozAgent) => !d.isWoz)
  )

  if (Object.keys(payload).length === 0) {
    context.commit(types.SET_WOZ_COPILOT_AGENT, wozCopilotAgent)
  }
}

export const toggleAgentState = async (
  context: ActionContext<WOZAgentsState, RootState>,
  payload: {
    id: string
    state: boolean
  }
) => {
  const { id, state } = payload

  await toggleEnable(id, state).catch(ex =>
    console.error('Error on disable agent', { ex, id })
  )

  const agents = context.state.agents

  if (agents) {
    const agent = agents.find(agent => agent.id === id)

    if (agent) {
      agent.active = state

      context.commit(types.SET_WOZ_AGENTS, agents)
    }
  }
}

export const getAgent = async (
  context: ActionContext<WOZAgentsState, RootState>,
  id: string
) => {
  const agent = id
    ? await getWozAgent(id).catch(ex =>
        console.error('Error on get agent', { ex, id })
      )
    : {
        voice: '',
        wordsToAvoid: [],
        instructions: '',
        isWoz: false,
        name: '',
        onlyMyContent: true,
        active: false,
        specialties: []
      }

  agent.specialties = agent.specialties ?? []

  context.commit(types.SET_WOZ_CURRENT_AGENT, agent)

  return agent
}

export const upsertWozAgent = async (
  context: ActionContext<WOZAgentsState, RootState>
) => {
  try {
    const { currentAgent, currentTab } = context.getters

    const name = currentAgent.name

    const i18nDraft = i18n?.t('woz.agents.edit.profile.name.draft')

    const id = await updateAgent({
      ...currentAgent,
      name:
        name !== ''
          ? name
          : `${i18nDraft} (${dayjs().format('DD/MM/YYYY HH:mm')})`
    }).catch(ex => console.error('Error on update agent', { ex, currentAgent }))

    context.dispatch(
      'dispatchAlert',
      {
        title: i18n?.t('woz.agents.edit.alerts.save.success'),
        variant: 'success'
      },
      { root: true }
    )

    if (currentTab === WOZ_AGENT_EDIT_TAB.INSTRUCTIONS && !!currentAgent?.id) {
      context.dispatch('setWozProfileCurrentTab', WOZ_AGENT_EDIT_TAB.CONTENT)
    }

    context.commit(types.SET_WOZ_CURRENT_AGENT, {
      ...currentAgent,
      id
    })
  } catch {
    context.dispatch(
      'dispatchAlert',
      {
        title: i18n?.t('woz.agents.edit.alerts.save.error'),
        variant: 'danger'
      },
      { root: true }
    )
  }
}

export const updateCurrentWozAgent = async (
  context: ActionContext<WOZAgentsState, RootState>,
  agent: WozAgent
) => {
  context.commit(types.SET_WOZ_CURRENT_AGENT, agent)
}

export const deleteWozAgent = async (
  context: ActionContext<WOZAgentsState, RootState>,
  id: string
) => {
  await deleteAgent(id).then(() => {
    const agents = context.state.agents

    if (agents) {
      const agentIndex = agents.findIndex(agent => agent.id === id)

      if (agentIndex >= 0) {
        agents.splice(agentIndex, 1)

        context.commit(types.SET_WOZ_AGENTS, agents)
      }
    }
  })
}

export const setWozProfileCurrentTab = (
  context: ActionContext<WOZAgentsState, RootState>,
  tab: WOZ_AGENT_EDIT_TAB
) => {
  context.commit(types.SET_WOZ_PROFILE_CURRENT_TAB, tab)
}
