import { ref, computed } from 'vue'

export function useDevice() {
  const userAgent = ref(navigator.userAgent.toLowerCase())

  const isIOS = computed(() => /iphone|ipad|ipod/.test(userAgent.value))
  const isAndroid = computed(() => /android/.test(userAgent.value))
  const isMobile = computed(() =>
    /iphone|ipad|ipod|android|blackberry|iemobile|opera mini/.test(
      userAgent.value
    )
  )
  const isBrowser = computed(() => {
    return typeof window !== 'undefined' && typeof document !== 'undefined'
  })
  const isFirefox = computed(() => /firefox/.test(userAgent.value))
  const isChrome = computed(
    () =>
      /chrome|chromium/.test(userAgent.value) && !/edg/.test(userAgent.value)
  )
  const isSafari = computed(() =>
    /^((?!chrome|android).)*safari/i.test(userAgent.value)
  )
  const isEdge = computed(() => /edg/.test(userAgent.value))
  const isOpera = computed(() => /opera|opr/.test(userAgent.value))

  const getBrowserName = computed(() => {
    if (isFirefox.value) return 'Firefox'
    if (isChrome.value) return 'Chrome'
    if (isSafari.value) return 'Safari'
    if (isEdge.value) return 'Edge'
    if (isOpera.value) return 'Opera'
    return 'Unknown'
  })

  const getOS = computed(() => {
    if (isIOS.value) return 'iOS'
    if (isAndroid.value) return 'Android'
    if (/win/.test(userAgent.value)) return 'Windows'
    if (/mac/.test(userAgent.value)) return 'macOS'
    if (/linux/.test(userAgent.value)) return 'Linux'
    return 'Unknown'
  })

  return {
    isIOS,
    isAndroid,
    isMobile,
    isBrowser,
    isFirefox,
    isChrome,
    isSafari,
    isEdge,
    isOpera,
    getBrowserName,
    getOS
  }
}

export default useDevice
