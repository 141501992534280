import { WOZ_AGENT_EDIT_TAB } from '@/modules/Woz/enums'

import { WozAgent, WOZAgentsState } from './interfaces'
import * as types from './mutation-types'

export default {
  [types.SET_WOZ_AGENTS](state: WOZAgentsState, agents: [WozAgent]) {
    state.agents = agents
  },
  [types.SET_WOZ_AGENTS_LOADING](state: WOZAgentsState, loading: boolean) {
    state.loadingAgents = loading
  },
  [types.SET_WOZ_CURRENT_AGENT](
    state: WOZAgentsState,
    currentAgent?: WozAgent
  ) {
    state.currentAgent = currentAgent
  },
  [types.SET_WOZ_COPILOT_AGENT](state: WOZAgentsState, copilotAgent: WozAgent) {
    state.copilotAgent = copilotAgent
  },
  [types.SET_WOZ_PROFILE_CURRENT_TAB](
    state: WOZAgentsState,
    tab: WOZ_AGENT_EDIT_TAB
  ) {
    state.currentTab = tab
  }
}
