import { ActionContext } from 'vuex'

import { getWozAgents } from '@/modules/Woz/agents/agents.service'

import { RootState, WozAgent } from '@/store/interfaces'

import {
  getVirtualAgentMetrics,
  getVirtualAgentConversationStatus
} from './analytics.service'
import { MetricTypes } from './interfaces'

export const loadVirtualAgentMetrics = async (
  context: ActionContext<any, RootState>,
  filters: { date: { dateIni: string; dateEnd: string }; agents: WozAgent[] }
) => {
  try {
    const ids = filters?.agents?.map(agent => agent.id).join(',')
    return await getVirtualAgentMetrics(filters.date, ids)
  } catch (err) {
    throw new Error('Error on get content gaps', { cause: err })
  }
}

export const loadAgents = async (
  context: ActionContext<any, RootState>,
  payload: {
    name?: string
    active?: boolean
  }
) => {
  try {
    return await getWozAgents(payload)
  } catch (err) {
    throw new Error('Error on get agents', { cause: err })
  }
}

export const loadConversations = async (
  context: ActionContext<any, RootState>,
  payload: {
    agents: WozAgent[]
    type: MetricTypes
    page: number
    date: { dateIni: string; dateEnd: string }
  }
) => {
  try {
    const agentIds = payload?.agents?.map(agent => agent.id).join(',')
    return await getVirtualAgentConversationStatus({
      type: payload.type,
      date: payload.date,
      agentIds,
      page: payload.page
    })
  } catch (err) {
    throw new Error('Error on get content gaps', { cause: err })
  }
}
