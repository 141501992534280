import { NavigationGuardNext, Route } from 'vue-router'

import { customFieldsRoutes } from '@/common/components/customFields/helper/routes'

import { RoleTypes } from '@/common/helpers/enums/role-types'

import store from '@/store'

const redirectLastestListRoute = (
  to: Route,
  from: Route,
  next: NavigationGuardNext
) => {
  const previousListRoute = store.getters['ticket/latestListRoute']
  if (to?.meta?.internalRoute && from?.name === 'edit' && previousListRoute) {
    to.meta.internalRoute = previousListRoute
  }
  next()
}

const redirectTicketLP = (next: NavigationGuardNext) => {
  const hasTicketFeature = store.getters.isTicketEnabled
  if (!hasTicketFeature) {
    next('/ticket/lp')
  }
  next()
}

const TICKET_LIST_PATH = '/ticket/list'
const TICKET_DATA_IMPORT = 'data-import'
const CUSTOM_FIELDS_TICKET_BREADCRUMB = 'customFields.list.breadcrumb.ticket'
const CUSTOM_FIELDS_MANAGE_BREADCRUMB = 'customFields.list.breadcrumb.manage'

export default [
  {
    path: '/ticket',
    name: 'ticket',
    beforeEnter: (_: any, __: any, next: any) => {
      redirectTicketLP(next)
    },
    redirect: TICKET_LIST_PATH,
    component: () => import('@/views/Ticket.vue'),
    children: [
      {
        path: 'search',
        name: 'search',
        meta: {
          iframeId: 'Main',
          internalRoute: 'embed/main/home/tickets/new-search//',
          permissionLevel: RoleTypes.Agent
        }
      },
      {
        path: 'list',
        name: 'list',
        beforeEnter: (to: any, from: any, next: any) => {
          return redirectLastestListRoute(to, from, next)
        },
        meta: {
          iframeId: 'Main',
          internalRoute: 'embed/main/home/tickets/new-list/NewTickets/',
          permissionLevel: RoleTypes.Agent
        }
      },
      {
        path: 'edit/:id',
        name: 'edit',
        props: true,
        meta: {
          iframeId: 'Main',
          internalRoute: 'embed/main/home/ticket/edit/:id/',
          permissionLevel: RoleTypes.Agent
        }
      },
      {
        path: 'copy/:id',
        name: 'copy',
        props: true,
        meta: {
          iframeId: 'Main',
          internalRoute: 'embed/main/home/ticket/copy/:id/',
          permissionLevel: RoleTypes.Agent
        }
      },
      {
        path: 'dashboard',
        name: 'dashboard',
        meta: {
          iframeId: 'Main',
          internalRoute: 'embed/main/home/dashboards/dashboard/ticket/',
          permissionLevel: RoleTypes.Agent
        }
      },
      {
        path: 'report',
        name: 'report',
        meta: {
          iframeId: 'Main',
          internalRoute: 'embed/main/home/report/list//',
          permissionLevel: RoleTypes.Agent
        },
        beforeEnter: (to: any, from: any, next: any) => {
          store
            .dispatch('getConfig')
            .then(() => {
              const config = store.getters['config']
              const userLogged = store.getters['userLogged']
              if (
                config.login.blockReportsViewByAgents &&
                userLogged?.roleType === RoleTypes.Agent
              ) {
                next(TICKET_LIST_PATH)
              }
              next()
            })
            .catch(() => {
              const userLogged = store.getters['userLogged']
              if (userLogged?.roleType === RoleTypes.Agent) {
                next(TICKET_LIST_PATH)
              }
              next()
            })
        }
      },
      {
        path: 'report/view/:id',
        name: 'report-view',
        props: true,
        meta: {
          iframeId: 'Main',
          internalRoute: 'embed/main/home/report/view/:id/',
          permissionLevel: RoleTypes.Agent
        }
      },
      {
        path: 'subject',
        name: 'subject',
        meta: {
          iframeId: 'Main',
          internalRoute: 'embed/main/home/subject/list//',
          permissionLevel: RoleTypes.Admin
        }
      },
      {
        path: 'subject/edit/:id',
        name: 'subject-edit',
        meta: {
          iframeId: 'Main',
          internalRoute: 'embed/main/home/subject/edit/:id/',
          permissionLevel: RoleTypes.Admin
        }
      },
      {
        path: 'forms',
        name: 'forms',
        meta: {
          iframeId: 'Main',
          internalRoute: 'embed/main/home/forms/list//',
          permissionLevel: RoleTypes.Admin
        }
      },
      {
        path: 'forms/edit/:id',
        name: 'forms-edit',
        meta: {
          iframeId: 'Main',
          internalRoute: 'embed/main/home/forms/edit/:id/',
          permissionLevel: RoleTypes.Admin
        }
      },
      {
        path: 'forms/add',
        name: 'forms-add',
        meta: {
          iframeId: 'Main',
          internalRoute: 'embed/main/home/forms/edit//',
          permissionLevel: RoleTypes.Admin
        }
      },
      {
        path: 'customlist',
        name: 'customlist',
        meta: {
          iframeId: 'Main',
          internalRoute: 'embed/main/home/view-editor/all-custom-lists//',
          permissionLevel: RoleTypes.Admin
        }
      },
      {
        path: 'products',
        name: 'products',
        meta: {
          iframeId: 'Main',
          internalRoute: 'embed/main/home/product/list-category//',
          permissionLevel: RoleTypes.Admin
        }
      },
      {
        path: 'product/edit/:id',
        name: 'product-edit',
        meta: {
          iframeId: 'Main',
          internalRoute: 'embed/main/home/product/edit/:id/',
          permissionLevel: RoleTypes.Admin
        }
      },
      {
        path: 'macro',
        name: 'macro',
        meta: {
          iframeId: 'Main',
          internalRoute: 'embed/main/home/macro/list/all/',
          permissionLevel: RoleTypes.Admin
        }
      },
      {
        path: 'macro/edit/:id',
        name: 'macro-edit',
        props: true,
        meta: {
          iframeId: 'Main',
          internalRoute: 'embed/main/home/macro/edit/:id/',
          permissionLevel: RoleTypes.Agent
        }
      },
      {
        path: 'automation',
        name: 'automation',
        meta: {
          iframeId: 'Main',
          internalRoute: 'embed/main/home/automation/list//',
          permissionLevel: RoleTypes.Admin
        }
      },
      {
        path: 'automation/edit/:id',
        name: 'automation-edit',
        props: true,
        meta: {
          iframeId: 'Main',
          internalRoute: 'embed/main/home/automation/edit/:id/',
          permissionLevel: RoleTypes.Agent
        }
      },
      {
        path: 'trigger',
        name: 'trigger',
        meta: {
          iframeId: 'Main',
          internalRoute: 'embed/main/home/trigger/list//',
          permissionLevel: RoleTypes.Admin
        }
      },
      {
        path: 'trigger/edit/:id',
        name: 'trigger-edit',
        props: true,
        meta: {
          iframeId: 'Main',
          internalRoute: 'embed/main/home/trigger/edit/:id/',
          permissionLevel: RoleTypes.Agent
        }
      },
      {
        path: 'sla',
        name: 'sla',
        meta: {
          iframeId: 'Main',
          internalRoute: 'embed/main/home/sla/list//',
          permissionLevel: RoleTypes.Admin
        }
      },
      {
        path: 'smartform',
        name: 'smartform',
        meta: {
          iframeId: 'Main',
          internalRoute: 'embed/main/home/smart-form/list//',
          permissionLevel: RoleTypes.Admin
        }
      },
      {
        path: 'smartform/edit/:id',
        name: 'smartform-edit',
        props: true,
        meta: {
          iframeId: 'Main',
          internalRoute: 'embed/main/home/smart-form/edit/:id/',
          permissionLevel: RoleTypes.Agent
        }
      },
      {
        path: 'workflow',
        name: 'workflow',
        meta: {
          iframeId: 'Main',
          internalRoute: 'embed/main/home/custom-workflow/list//',
          permissionLevel: RoleTypes.Admin
        }
      },
      {
        path: 'workflow/edit/:id',
        name: 'workflow-edit',
        props: true,
        meta: {
          iframeId: 'Main',
          internalRoute: 'embed/main/home/custom-workflow/register/:id/',
          permissionLevel: RoleTypes.Agent
        }
      },
      {
        path: 'helpcenter',
        name: 'helpcenter',
        meta: {
          iframeId: 'Main',
          internalRoute: 'embed/main/home/help-center/edit//',
          permissionLevel: RoleTypes.Admin
        }
      },
      {
        path: 'company',
        name: 'company',
        meta: {
          iframeId: 'Main',
          internalRoute: 'embed/main/home/company/company-contacts//',
          permissionLevel: RoleTypes.Admin
        }
      },
      {
        path: 'facebook',
        name: 'facebook',
        meta: {
          iframeId: 'Main',
          internalRoute: 'embed/main/home/facebook/list//',
          permissionLevel: RoleTypes.Admin
        }
      },
      {
        path: 'nf-box',
        name: 'nf-box',
        meta: {
          iframeId: 'Main',
          internalRoute: 'embed/main/home/nfbox/settings//',
          permissionLevel: RoleTypes.Admin
        }
      },
      {
        path: 'preferences',
        name: 'preferences',
        meta: {
          iframeId: 'Main',
          internalRoute: 'embed/main/home/company/preferences//',
          permissionLevel: RoleTypes.Admin
        }
      },
      {
        path: 'security',
        name: 'security',
        meta: {
          iframeId: 'Main',
          internalRoute: 'embed/main/home/company/security//',
          permissionLevel: RoleTypes.Admin
        }
      },
      {
        path: 'calendar',
        name: 'calendar',
        meta: {
          iframeId: 'Main',
          internalRoute: 'embed/main/home/calendar/list//',
          permissionLevel: RoleTypes.Admin
        }
      },
      {
        path: 'calendar/list-holidays',
        name: 'calendar-list-holidays',
        meta: {
          iframeId: 'Main',
          internalRoute: 'embed/main/home/calendar/list/list-holidays/',
          permissionLevel: RoleTypes.Admin
        }
      },
      {
        path: 'calendar/edit-calendar',
        name: 'calendar-edit-calendar-create',
        meta: {
          iframeId: 'Main',
          internalRoute: 'embed/main/home/calendar/edit-calendar//',
          permissionLevel: RoleTypes.Admin
        }
      },
      {
        path: 'calendar/edit-calendar/:id',
        name: 'calendar-edit-calendar',
        meta: {
          iframeId: 'Main',
          internalRoute: 'embed/main/home/calendar/edit-calendar/:id/',
          permissionLevel: RoleTypes.Admin
        }
      },
      {
        path: 'calendar/edit-holidays',
        name: 'calendar-edit-holidays-create',
        meta: {
          iframeId: 'Main',
          internalRoute: 'embed/main/home/calendar/edit-holidays//',
          permissionLevel: RoleTypes.Admin
        }
      },
      {
        path: 'calendar/edit-holidays/:id',
        name: 'calendar-edit-holidays',
        meta: {
          iframeId: 'Main',
          internalRoute: 'embed/main/home/calendar/edit-holidays/:id/',
          permissionLevel: RoleTypes.Admin
        }
      },
      {
        path: TICKET_DATA_IMPORT,
        name: TICKET_DATA_IMPORT,
        meta: {
          iframeId: 'Main',
          internalRoute: 'embed/main/home/data-import/list//',
          permissionLevel: RoleTypes.Admin
        }
      },
      {
        path: 'groups',
        name: 'groups',
        meta: {
          iframeId: 'Main',
          internalRoute: 'embed/main/home/groups/list//',
          permissionLevel: RoleTypes.Admin
        }
      },
      {
        path: 'groups/profile/:id',
        name: 'groups-profile',
        meta: {
          iframeId: 'Main',
          internalRoute: 'embed/main/home/groups/profile/:id/',
          permissionLevel: RoleTypes.Admin
        }
      },
      {
        path: 'ticket-access',
        name: 'ticket-access',
        meta: {
          iframeId: 'Main',
          internalRoute: 'embed/main/home/person/list-simple//',
          permissionLevel: RoleTypes.Admin
        }
      }
    ]
  },
  {
    path: '/tickets',
    name: 'tickets',
    beforeEnter: (_: any, __: any, next: any) => {
      redirectTicketLP(next)
    },
    component: () => import('@/views/Tickets.vue'),
    children: [
      {
        path: 'apps',
        name: 'apps',
        component: () => import('@/views/TicketApps.vue'),
        meta: {
          permissionLevel: RoleTypes.Admin
        },
        children: [
          {
            path: 'voice/:provider',
            name: 'ticket-voice',
            component: () =>
              import(
                '@/modules/Ticket/apps/ticket-voice/components/TicketVoiceSettings.vue'
              )
          }
        ]
      },
      {
        path: 'survey',
        name: 'ticket-survey',
        component: () =>
          import('@/modules/Ticket/survey/components/Survey.vue'),
        meta: { permissionLevel: RoleTypes.Admin }
      },
      {
        path: 'webhook',
        name: 'ticket-webhook',
        component: () =>
          import('@/modules/Ticket/webhook/components/Webhook.vue'),
        meta: { permissionLevel: RoleTypes.Admin }
      },
      {
        path: 'ticket-settings',
        name: 'ticket-settings',
        component: () =>
          import(
            '@/modules/Ticket/ticket-settings/components/TicketSettings.vue'
          )
      },
      {
        path: 'token-api',
        name: 'ticket-token-api',
        component: () =>
          import('@/modules/Ticket/token-api/components/TokenApi.vue')
      },
      {
        path: 'talktous',
        name: 'ticket-talktous',
        component: () =>
          import('@/modules/Ticket/talktous/components/Talktous.vue'),
        meta: { permissionLevel: RoleTypes.Admin }
      },
      {
        path: 'ticket-fields',
        name: 'ticket-fields',
        meta: {
          permissionLevel: RoleTypes.Admin
        },
        component: () =>
          import('@/common/components/customFields/List/List.vue'),
        props: {
          breadcrumb: [
            {
              i18nKey: CUSTOM_FIELDS_TICKET_BREADCRUMB,
              link: TICKET_LIST_PATH
            },
            {
              i18nKey: CUSTOM_FIELDS_MANAGE_BREADCRUMB
            },
            {
              i18nKey: `customFields.list.breadcrumb.customField.ticketFields`
            }
          ]
        }
      },
      ...customFieldsRoutes
    ]
  }
]
