import { ActionContext } from 'vuex'

import { InboxTypes } from '@/common/helpers/enums/inbox-types'
import { ChatSettings } from '@/common/helpers/interfaces/chat-settings'

import { ChatService } from '@/common/services/chat'
import { GroupsService } from '@/common/services/groups'

import {
  ChatState,
  InboxCounters,
  InboxGroup,
  InboxGroupsEdit,
  RootState
} from '@/store/interfaces'

import { inboxFilters } from './inbox'
import * as types from './mutations-types'

const MAX_COUNT_INBOX = 999

const groupService = new GroupsService()

export const getInboxCounters = async (
  context: ActionContext<ChatState, RootState>
) => {
  const { id: userLoggedId } = context.rootGetters.userLogged

  const { isAdmin, isResponseServiceTimeoutEnabled } = context.rootGetters

  const filter = inboxFilters(
    userLoggedId,
    isAdmin,
    isResponseServiceTimeoutEnabled
  )

  const chatService = new ChatService()

  const [
    all,
    byAgent,
    unread,
    mentioned,
    participation,
    withoutResponsible,
    chatBot,
    timeExceeded
  ] = await chatService.getChatConversationCount(filter)

  const getCount = (inboxCount: number) =>
    inboxCount > MAX_COUNT_INBOX ? '999+' : inboxCount

  const inboxCounters: InboxCounters = {
    [InboxTypes.ALL]: getCount(all),
    [InboxTypes.BY_AGENT]: getCount(byAgent),
    [InboxTypes.UNREAD]: getCount(unread),
    [InboxTypes.MENTIONED]: getCount(mentioned),
    [InboxTypes.PARTICIPATION]: getCount(participation),
    [InboxTypes.WITHOUT_RESPONSIBLE]: getCount(withoutResponsible),
    [InboxTypes.CHATBOT]: getCount(chatBot)
  }

  if (isResponseServiceTimeoutEnabled)
    inboxCounters.timeExceeded = getCount(timeExceeded)

  context.commit(types.SET_INBOX_COUNTERS, inboxCounters)
}

export const getInboxGroup = async (
  context: ActionContext<ChatState, RootState>
) => {
  const response = await groupService.getInboxGroups()

  context.commit(types.SET_INBOX_GROUPS, response.data.groups)

  return response.data
}

export const setGroupInboxSelected = (
  context: ActionContext<ChatState, RootState>,
  groupInboxNameSelected: string
) => {
  context.commit(types.SET_GROUP_INBOX_NAME_SELECTED, groupInboxNameSelected)
}

export const getInboxGroupsEdit = async (
  context: ActionContext<ChatState, RootState>
) => {
  const response = await groupService.get()

  context.commit(types.SET_INBOX_GROUPS_EDIT, response)
}
export const setInboxGroupsEdit = async (
  context: ActionContext<ChatState, RootState>,
  groupInbox: InboxGroupsEdit[]
) => {
  await groupService.update(groupInbox)

  context.commit(types.SET_INBOX_GROUPS_EDIT, groupInbox)

  await context.dispatch('getInboxGroup', {
    page: 1,
    limit: 1000
  })
}
export const updateInboxGroupEdit = async (
  context: ActionContext<ChatState, RootState>,
  groupInbox: Partial<InboxGroupsEdit[]>
) => {
  const { inboxGroupsEdit } = context.getters

  const newInboxGroupsEdit = inboxGroupsEdit
    ? inboxGroupsEdit.map((group: InboxGroup) => ({
        ...group,
        ...groupInbox.find(newGroup => newGroup?.id === group.id)
      }))
    : groupInbox

  await groupService.update(newInboxGroupsEdit)

  context.commit(types.SET_INBOX_GROUPS_EDIT, newInboxGroupsEdit)

  await context.dispatch('getInboxGroup', {
    page: 1,
    limit: 1000
  })
}

export const upsertGroupInbox = (
  context: ActionContext<ChatState, RootState>,
  groupInbox: Partial<InboxGroup[]>
) => {
  const inboxGroups = context.getters.inboxGroups ?? []

  const allGroupsExists = groupInbox.every(group =>
    inboxGroups?.find((g: InboxGroup) => g.id === group?.id)
  )

  let newInboxGroups = inboxGroups.map((group: InboxGroup) => {
    const newGroup = groupInbox.find(newGroup => newGroup?.id === group.id)

    if (newGroup) newGroup.totalRooms = group.totalRooms + newGroup.totalRooms

    return {
      ...group,
      ...newGroup
    }
  })

  if (!allGroupsExists) {
    const groupNotExists = groupInbox.filter(
      group => !inboxGroups?.find((g: InboxGroup) => g.id === group?.id)
    )
    newInboxGroups = [...newInboxGroups, ...groupNotExists]
  }

  context.commit(types.SET_INBOX_GROUPS, newInboxGroups)
}

export const removeGroupInbox = (
  context: ActionContext<ChatState, RootState>,
  groupId: string
) => {
  const { inboxGroups } = context.getters

  const newInboxGroups = inboxGroups
    ? inboxGroups.filter((group: InboxGroup) => group.id !== groupId)
    : []

  context.commit(types.SET_INBOX_GROUPS, newInboxGroups)
}

let updateInboxLoopInterval: any

export const startUpdateInboxCountersLoop = (
  context: ActionContext<ChatState, RootState>
) => {
  const updateDelayMs = 1000 * 60 * 10 // 10 minutes

  if (!updateInboxLoopInterval) {
    updateInboxLoopInterval = setInterval(() => {
      context.dispatch('getInboxCounters')
    }, updateDelayMs)
  }
}

export const stopUpdateInboxCountersLoop = () => {
  if (updateInboxLoopInterval) {
    clearInterval(updateInboxLoopInterval)

    updateInboxLoopInterval = undefined
  }
}

export const updateChatSettings = async (
  context: ActionContext<ChatState, RootState>,
  settings: ChatSettings
) => {
  const chatService = new ChatService()

  const updatedSettings = await chatService.save(settings)

  context.commit(types.UPDATE_CHAT_SETTINGS, updatedSettings)

  return updatedSettings
}

export const openChatConversation = async (
  context: ActionContext<ChatState, RootState>,
  roomKey: string
) => {
  context.commit(types.OPEN_CHAT_CONVERSATION_ROOM_KEY, roomKey)
}

export const cleanChatConversationRoomKey = async (
  context: ActionContext<ChatState, RootState>
) => {
  context.commit(types.OPEN_CHAT_CONVERSATION_ROOM_KEY, null)
}
